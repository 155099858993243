import React, { Component } from "react"

import uproot from '../images/uproot.jpg'

import { FaChevronRight } from "react-icons/fa"

import './layout.css'

const descriptionText = "In UPROOT I engage in a conversation with people who have left their home countries and came to live in the US. I talk to women and men who feel uprooted and whose lives unfold between cultures. We talk about the challenges and opportunities that each of these women and men have faced in their journey to becoming multicultural citizens in the American workspace and in their communities, and we reflect on how those experiences have shaped their understanding of the US and their respective countries."

class Contact extends Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        if (this.props.windowWidth > 500) {
            return (
                <>
                <div style = {sectionWrapper}>
                    <div style = {sectionStyle}>
                        <h1 style={headerText}>
                            Contact Me
                        </h1>
                        <div style = {mainContainerStyle} >
                            <a href = "mailto:giraudy@american.edu" target = "_blank" style = {contactLink} >
                                <div style = {contactElement}>
                                    Email
                                </div>
                            </a>
                            <a href = "https://bit.ly/398y3AZ" target = "_blank" style = {contactLink} >
                                <div style = {contactElement}>
                                    Twitter
                                </div>
                            </a>
                            <a href = "https://www.american.edu/media/request-an-interview.cfm" target = "_blank" style = {contactLink} >
                                <div style = {contactElement}>
                                    Request Interview
                                </div>
                            </a>
                        </div>
                        <footer style={{
                        marginTop: `2rem`
                    }}>
                        <p style = {copyrightStyle}>
                            Designed by Camilo Calvo-Alcaniz
                        </p>
                    </footer>
                    </div>
                </div>
                </>
            )
        } else {
            return (
                <>
                <div style = {sectionWrapperMobile}>
                    <div style = {sectionStyle}>
                        <h1 style={headerTextMobile}>
                            Contact Me
                        </h1>
                        <div style = {mainContainerStyle} >
                            <a href = "mailto:giraudy@american.edu" target = "_blank" style = {contactLink} >
                                <div style = {contactElementMobile}>
                                    Email
                                </div>
                            </a>
                            <a href = "https://bit.ly/398y3AZ" target = "_blank" style = {contactLink} >
                                <div style = {contactElementMobile}>
                                    Twitter
                                </div>
                            </a>
                            <a href = "https://www.american.edu/media/request-an-interview.cfm" target = "_blank" style = {contactLink} >
                                <div style = {contactElementMobile}>
                                    Request Interview
                                </div>
                            </a>
                        </div>
                        <footer style={{
                        marginTop: `2rem`
                    }}>
                        <p style = {copyrightStyleMobile}>
                        Copyright © {new Date().getFullYear()}. All rights reserved.
                        </p>
                    </footer>
                    </div>
                </div>
                </>
            )
        }
    }
}
 
const sectionWrapper = {
    padding: '30px 80px 4px 80px',
    backgroundColor: '#3b576b',
    color: 'white',
}

const sectionWrapperMobile = {
    ...sectionWrapper,
    padding: '30px 10px 4px 10px',
}

const sectionStyle = {
    maxWidth: '800px',
    margin: '0px auto 0px auto',
    flexGrow: '1',
}

const headerText = {
    fontFamily: 'Avant Garde',
    fontSize: '36px',
    margin: '20px auto 0px auto',
    textAlign: 'center',
    lineHeight: '30px'
}

const headerTextMobile = {
    ...headerText, 
    fontSize: '32px',
    marginBottom: '20px'
}

const mainContainerStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '100%',
    margin: '20px auto 10px auto',
}

const contactLink = {
    textDecoration: 'none'
}

const contactElement = {
    fontFamily: 'Avant Garde',
    fontSize: '22px',
    textAlign: 'center',
    width: '100px'
}

const contactElementMobile = {
    ...contactElement,
    fontSize: '18px',
    lineHeight: '20px'
}

const copyrightStyle = {
    margin: '40px 0px 20px 18px',
}

const copyrightStyleMobile = {
    margin: '20px 0px 20px 22px',
}


export default Contact
