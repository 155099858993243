import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutInfo from "./aboutInfo"

import Contact from "../components/contact"

class AboutPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
    }

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions() {
    let windowWidth =
      typeof window !== "undefined" ? window.visualViewport.width : 0
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0

    this.setState({ windowWidth, windowHeight })
  }

  render() {
    const { windowWidth } = this.state
    return (
      <Layout rootPage={false} windowWidth={windowWidth}>
        <SEO title="About Agustina Giraudy" />
        <AboutInfo windowWidth={windowWidth} />
        <Contact windowWidth={windowWidth} />
      </Layout>
    )
  }
}

export default AboutPage
