import React, { Component } from "react"
import { Spring } from "react-spring/renderprops"

import teaching from "../images/teaching.jpg"
import recording from "../images/recording.jpg"
import recording2 from "../images/recording2.jpg"

class AboutInfo extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let bioTextOne = (
      <>
        As an Argentinian who grew up in a peripheral, semi-democratic province,
        I have witnessed firsthand the markedly different access to and exercise
        of political and civil rights among more deprived citizens of peripheral
        areas versus the more privileged inhabitants residing in urban centers.
        I draw on my personal experiences, research, extensive on-site
        fieldwork, and professional training in political science, both in the
        United States and Latin America, to examine questions related to the
        political and social processes that unfold beyond the central state,
        i.e., what political scientists refer to as subnational research. The
        overall question that drives my research program is: What are the causes
        of territorial unevenness in political processes that lead to unequal
        access and exercise of citizens' political, social, and civil rights?” I
        have explored this question in the context of democratic and
        authoritarian regimes, the provision of health care, the implementation
        of policies, the territorial reach of the state, the functioning of
        local courts, among others.
      </>
    )

    let bioTextTwo = (
      <>
        Beyond academia, I am the host of the UPROOT podcast, in which I
        interview women and men from Latin America who left their home countries
        and now live in the US. The conversations revolve around the challenges
        and opportunities that each of my guests face in their journey to
        becoming multicultural citizens in the American workspace and in their
        communities. We also reflect on how those experiences have shaped my
        guests' understanding of the US and their respective countries.
      </>
    )

    if (this.props.windowWidth > 1100) {
      return (
        <div style={sectionStyle}>
          <div style={textBoxStyle}>
            <h1 style={pageHeader}>About Me</h1>
            <p style={mainText}>{bioTextOne}</p>
            <p style={mainText}>{bioTextTwo}</p>
          </div>
          <div style={imageBoxStyle}>
            <img
              src={recording2}
              alt="Recording UPROOT Celestino Barrera Carlos Castillo"
              style={imageStyle}
            />
            <img src={teaching} alt="Teaching" style={imageStyleCover} />
            <img
              src={recording}
              alt="Recording UPROOT Ernesto Calvo"
              style={imageStyle}
            />
          </div>
        </div>
      )
    } else if (
      (this.props.windowWidth <= 1100) &
      (this.props.windowWidth > 500)
    ) {
      return (
        <div style={sectionStyle}>
          <div style={textBoxStyleMobile}>
            <h1 style={pageHeader}>About Me</h1>
            <p style={mainText}>{bioTextOne}</p>
            <img src={teaching} alt="Teaching" style={imageStyleCover} />
            <p style={mainText}>{bioTextTwo}</p>
            <img
              src={recording2}
              alt="Recording UPROOT Celestino Barrera Carlos Castillo"
              style={imageStyle}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div style={sectionStyle}>
          <div style={textBoxStyleMobile}>
            <h1 style={pageHeaderMobile}>About Me</h1>
            <p style={mainTextMobile}>{bioTextOne}</p>
            <img src={teaching} alt="Teaching" style={imageStyleCover} />
            <p style={mainTextMobile}>{bioTextTwo}</p>
            <img
              src={recording2}
              alt="Recording UPROOT Celestino Barrera Carlos Castillo"
              style={imageStyle}
            />
          </div>
        </div>
      )
    }
  }
}

const sectionStyle = {
  maxWidth: "1200px",
  margin: "40px auto 30px auto",
  padding: "0px 20px 0px 20px",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
}

const pageHeader = {
  fontFamily: "Avant Garde",
  fontSize: "26px",
  margin: "20px 0px 40px 0px",
  color: "black",
}

const pageHeaderMobile = {
  ...pageHeader,
  fontSize: "22px",
  margin: "20px 0px 20px 0px",
}

const mainText = {
  fontFamily: "Caslon",
  fontSize: "20px",
  textAlign: "left",
  lineHeight: "24px",
}

const mainTextMobile = {
  ...mainText,
  fontSize: "19px",
  lineHeight: "19px",
}

const textBoxStyle = {
  width: "53%",
  textAlign: "justify",
}

const textBoxStyleMobile = {
  maxWidth: "800px",
  margin: "auto",
  textAlign: "justify",
}

const imageBoxStyle = {
  width: "40%",
  position: "relative",
  top: "60px",
}

const imageStyle = {
  width: "100%",
  borderRadius: "4px",
  marginBottom: "40px",
}

const imageStyleCover = {
  width: "100%",
  height: "200px",
  borderRadius: "4px",
  marginBottom: "40px",
  objectFit: "cover",
}

export default AboutInfo
